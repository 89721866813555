import { useEffect, useState, useSyncExternalStore } from "react";
import getRecordStatusesAvailable from "../../Models/RecordStatusAvailable";
import RecordService from "../../Services/Record";
import MetadataService from "../../Services/Metadata";

const RecordStatusModalComponent = ({
  isModalOpen,
  setIsModalOpen,
  recordData,
}) => {
  const metadataService = new MetadataService();

  const [isLoadingStatuses, setIsLoadingStatuses] = useState(true);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    setIsLoadingStatuses(true);
    metadataService
      .getRecordStatuses()
      .then((response) => {
        setStatuses(response.data.data);
      })
      .finally(() => {
        setIsLoadingStatuses(false);
      });
  }, []);

  const getStatusById = (id) => {
    return statuses.filter((i) => {
      return i.CODSTATUS == id;
    }).length > 0
      ? statuses.filter((i) => {
          return i.CODSTATUS == id;
        })[0]
      : {};
  };

  const [statusesAvailable, setStatusesAvailable] = useState([]);
  const recordService = new RecordService();

  useEffect(() => {
    if (recordData && recordData.CODFICHA) {
      setStatusesAvailable(
        getRecordStatusesAvailable(recordData.STATUSIMP || "01")
      );
    }
  }, [recordData]);

  useEffect(() => {
    console.log({ statusesAvailable });
  }, [statusesAvailable]);

  if (!recordData) {
    return "";
  }

  return (
    <div
      tabindex="-1"
      className={`modal fade ${isModalOpen ? "show" : ""}`}
      style={{
        display: isModalOpen ? "block" : "none",
        background: "rgba(0, 0, 0, .6)",
      }}
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Alteração de Status da Ficha</h5>
            <button
              type="button"
              class="btn-close"
              onClick={() => setIsModalOpen(false)}
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>
              Status atual: {recordData.STATUSIMP && statuses.length > 0 ? getStatusById(recordData.STATUSIMP).NOME : "Aguarde..."}
            </p>
            {statusesAvailable.map((status) => (
              <>
                <button
                  className="btn"
                  style={{
                    backgroundColor: status.color,
                    color: "white",
                    margin: "5px",
                  }}
                  disabled={!status.enabled}
                  onClick={() => {
                    const newRecordData = {
                      ...recordData,
                      STATUSIMP: status.codStatus,
                    };

                    console.log({ newRecordData });
                    recordService.updateRecord(
                      recordData.CODFICHA,
                      newRecordData
                    );
                  }}
                >
                  {status.description}
                </button>
              </>
            ))}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              onClick={() => setIsModalOpen(false)}
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordStatusModalComponent;
