import { useEffect, useState } from "react";

const TextInputComponent = ({
  label,
  placeholder,
  className = "",
  formatter,
  value,
  onChange,
  validator,
  dataKey,
  disabled = false,
  required = false,
  searchable = false,
  loading = false,
  boldLabel = false,
  onSearch = () => {},
  viewFormatter = (i) => i,
}) => {
  const [error, setError] = useState(null);

  const [isValid, setIsValid] = useState(null);

  const handleChange = (e) => {
    if (onChange) {
      onChange(dataKey, formatter ? formatter(e.target.value) : e.target.value);
    }

    if (required === true && e.target.value === "") {
      setError("Campo obrigatório");
      setIsValid(null);
    } else if (validator) {
      const isValid = validator(e.target.value);

      if (isValid === false) {
        setError("Valor inválido");
        setIsValid(null);
      } else if (isValid === true) {
        setError(null);
        setIsValid(true);
      } else {
        setError(null);
        setIsValid(null);
      }
    }
  };

  return (
    <div class={`mb-3 ${className}`}>
      {label && <label className={`form-label ${boldLabel ? 'fw-bold' : ''}`}>{label}</label>}

      <div class="input-group">
        <input
          type="text"
          disabled={disabled || loading}
          className={`form-control rounded-1 ${
            error ? "is-invalid" : isValid ? "is-valid" : ""
          }`}
          placeholder={placeholder}
          onChange={handleChange}
          required={required}
          value={
            value && typeof value == "object"
              ? viewFormatter(value[dataKey])
              : typeof value == "string"
              ? value
              : ""
          }
        />

        {searchable ? (
          <button
            class="  btn btn-success   text-nowrap"
            onClick={onSearch}
            disabled={loading}
          >
            {loading ? (
              <>
                <span
                  class="spinner-border spinner-border-sm me-1"
                  aria-hidden="true"
                ></span>
                <span role="status">Aguarde...</span>
              </>
            ) : (
              "Buscar"
            )}
          </button>
        ) : (
          ""
        )}

      
      </div>

      {error ? <div class="form-text text-danger">{error}</div> : ""}
    </div>
  );
};

export default TextInputComponent;
