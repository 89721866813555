import { useState, useEffect } from "react";

import NextButtonComponent from "../Buttons/NextButton";
import TextInputComponent from "../Inputs/TextInput";
import PreviousButtonComponent from "../Buttons/PreviousButton";
import RecordService from "../../Services/Record";
import LocationService from "../../Services/Location";
import MetadataService from "../../Services/Metadata";
import SelectInputComponent from "../Inputs/SelectInput";
import OfferService from "../../Services/Offer";
import {
  formatDate,
  formatMoney,
  formatNumber,
  formatPercentage,
  getNextMonthDate,
  notifyError,
} from "../../Utils";
import GenericButtonComponent from "../Buttons/GenericButton";
import TableComponent from "../Table";
import DateInputComponent from "../Inputs/DateInput";
import NumberInputComponent from "../Inputs/NumberInput";
import TokenModalComponent from "../Modals/TokenModal";
import TokenService from "../../Services/Token";
import ReportService from "../../Services/Report";
import ArrowDown from "../../Assets/Icons/arrowDown.svg";
const Step5 = ({
  previousStep,
  nextStep,
  codRecord,
  setCodRecord,
  noAction = false,
}) => {
  const recordService = new RecordService();
  const locationService = new LocationService();
  const metadataService = new MetadataService();
  const offerService = new OfferService();
  const tokenService = new TokenService();
  const reportService = new ReportService();

  useEffect(() => {
    if (!noAction) {
      reportService.logRecordProgress(codRecord, "Simulação de valores.");
    }
  }, []);

  const [isStepValid, setIsStepValid] = useState(false);

  const [tokens, setTokens] = useState([]);
  const [installments, setInstallments] = useState({});

  const [finalPrice, setFinalPrice] = useState(0);

  const [isLoadingRecord, setIsLoadingRecord] = useState(true);

  const [formData, setFormData] = useState({ VALOR_CURSO: "" });
  const [isSaving, setIsSaving] = useState(false);

  const [isTokenModalOpen, setIsTokenModalOpen] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState({
    loading: false,
    data: null,
  });

  const [offerPrice, setOfferPrice] = useState({
    loading: false,
    data: null,
  });

  const [discount, setDiscount] = useState(0);
  const [entryValue, setEntryValue] = useState(0.01);
  const [installmentsDueDate, setInstallmentsDueDate] = useState();

  const [isInstallmentTokenApplied, setIsInstallmentTokenApplied] =
    useState(false);
  const [numberOfInstallments, setNumberOfInstallments] = useState(0);

  useEffect(() => {
    tokenService.getByRecordId(codRecord).then((response) => {
      setTokens(
        Array.isArray(response.data.data)
          ? response.data.data.filter((item) => item.ATIVO == "S")
          : []
      );
    });
  }, []);

  useEffect(() => {
    setFinalPrice(
      offerPrice.data
        ? offerPrice.data.PRECO - (offerPrice.data.PRECO * discount) / 100
        : 0
    );
  }, [offerPrice, discount]);

  useEffect(() => {
    formData.VALOR_CURSO = finalPrice;
  }, [finalPrice]);

  const handleTokenApply = (token) => {
    if (token.TIPOTOKEN == "01" && discount == 100) {
      alert("Desconto máximo atingido.");
      return;
    }

    setTokens(
      tokens.map((t) => {
        if (t.ID == token.ID) {
          t.APLICADO = true;
          return t;
        } else {
          return t;
        }
      })
    );

    if (token.TIPOTOKEN == "01") {
      let newDiscount = discount + parseFloat(token.VALOR);

      if (newDiscount > 100) {
        newDiscount = 100;
      }

      setDiscount(newDiscount);
    }

    if (token.TIPOTOKEN == "02") {
      setIsInstallmentTokenApplied(true);
    }
  };

  const handleTokenRemove = (token) => {
    // Atualiza o array de tokens, marcando o token removido como não aplicado
    const updatedTokens = tokens.map((t) => {
      if (t.ID === token.ID) {
        return { ...t, APLICADO: false };
      }
      return t;
    });
    setTokens(updatedTokens);

    if (token.TIPOTOKEN === "01") {
      // Garante que o valor máximo do token seja 100%
      const tokenDiscount = Math.min(parseFloat(token.VALOR), 100);

      // Filtra os tokens ativos de tipo "01"
      const activeTokens = updatedTokens.filter(
        (t) => t.APLICADO && t.TIPOTOKEN === "01"
      );

      // Se for o único token ativo, zera o desconto
      if (activeTokens.length === 0) {
        setDiscount(0);
      } else {
        // Caso contrário, subtrai o desconto do token removido do desconto total atual
        setDiscount(discount - tokenDiscount);
      }
    }

    if (token.TIPOTOKEN === "02") {
      setIsInstallmentTokenApplied(false);
      setNumberOfInstallments(null);
    }
  };

  const [contractTotalValue, setContractTotalValue] = useState(0);

  useEffect(() => {
    if (installments.data) {
      const total = installments.data.reduce(
        (acc, item) => acc + parseFloat(item.VALORORIGINAL),
        0
      );

      setContractTotalValue(total);
    }
  }, [installments]);

  useEffect(() => {
    console.log({ offerPrice });
  }, [offerPrice]);

  useEffect(() => {
    console.log({ formData });
  }, [formData]);

  useEffect(() => {
    setIsStepValid(
      formData.ESTADONATAL &&
        formData.NOME &&
        formData.NATURALIDADE &&
        formData.DTNASCIMENTO &&
        Array.isArray(installments.data) &&
        installments.data.length > 0
    );
  }, [formData]);

  useEffect(() => {
    setPaymentMethods((prevState) => ({ ...prevState, loading: true }));
    metadataService
      .getPaymentMethods()

      .then((response) => {
        setPaymentMethods((prevState) => ({
          ...prevState,
          data: response.data.data,
        }));
      })

      .catch(notifyError)

      .finally(() => {
        setPaymentMethods((prevState) => ({ ...prevState, loading: false }));
      });
  }, []);

  useEffect(() => {
    if (formData.CODCOLIGADA && formData.IDHABILITACAOFILIAL) {
      setOfferPrice((prevState) => ({ ...prevState, loading: true }));

      const offerId = `${formData.CODCOLIGADA}| |${formData.IDHABILITACAOFILIAL}`;
      offerService
        .getOfferPrice(offerId)
        .then((response) => {
          if (!response.data.data?.PRECO) {
            alert(
              `IDHABILITACAOFILIAL ${formData.IDHABILITACAOFILIAL} Sem preço cadastrado.`
            );
            return;
          }

          setOfferPrice((prevState) => ({
            ...prevState,
            data: response.data.data,
          }));
        })

        .catch(notifyError)

        .finally(() => {
          setOfferPrice((prevState) => ({ ...prevState, loading: false }));
        });
    }
  }, [formData.CODCOLIGADA, formData.IDHABILITACAOFILIAL]);

  useEffect(() => {
    if (offerPrice.data) {
      setFormData({
        ...formData,
        VALOR_CURSOBRUTO: offerPrice.data.PRECO,
        VALOR_CURSO: offerPrice.data.PRECO,
        VALOR_TAXA: offerPrice.data.TAXA,
      });
    }
  }, [offerPrice]);

  const updateFormData = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  useEffect(() => {
    setIsLoadingRecord(true);
    recordService
      .getRecordById(codRecord)
      .then((response) => {
        setFormData(response.data.data);

        if (noAction) {
          recordService
            .getSavedInstallments(codRecord)

            .then((response) => {
              if (response.data.data.length > 0) {
                setInstallments(response.data);
              }
            })
            .finally(() => {
              setIsLoadingRecord(false);
            });
        } else {
          setIsLoadingRecord(false);
        }
      })
      .catch((error) => {
        notifyError(error);
        setIsLoadingRecord(false);
      });
  }, [codRecord]);

  const handleSubmit = () => {
    setIsSaving(true);

    if (!noAction) {
      reportService.logRecordProgress(codRecord, "Conclusão de Simulação.");
    }
    recordService
      .updateRecord(codRecord, formData)
      .then(() => nextStep())
      .catch(notifyError)
      .finally(() => setIsSaving(false));
  };

  const [isGeneratingInstallments, setIsGeneratingInstallments] =
    useState(false);

  const generateInstallments = () => {
    setIsSaving(true);
    setInstallments({});
    recordService
      .updateRecord(codRecord, formData)
      .finally(() => setIsSaving(false));

    setIsGeneratingInstallments(true);
    offerService
      .generateInstallments(
        `${formData.CODCOLIGADA}||${formData.IDHABILITACAOFILIAL}`,
        formData.FORMAPAG,
        formData.MODOPARC || "P",
        codRecord,
        formData.DATAINICVENC,
        entryValue,
        numberOfInstallments,
        installmentsDueDate
      )
      .then((response) => setInstallments(response.data))
      .finally(() => {
        setIsGeneratingInstallments(false);
      });
  };

  useEffect(() => {
    console.log({ installments });
  }, [installments]);

  useEffect(() => {
    if (isInstallmentTokenApplied === true) {
      updateFormData("TAXA_REAJUSTE", 0);
      updateFormData("FORMAPAG", "");
    }
  }, [isInstallmentTokenApplied]);

  const installmentsTableFields = [
    {
      key: "PARCELA",
      title: "Parcela",
    },
    {
      key: "NOMESERVICO",
      title: "Serviço",
    },
    {
      key: "VALORORIGINAL",
      title: "Valor",
      format: formatMoney,
    },
    {
      key: "DTVENCIMENTO",
      title: "Data de Vencimento",
      format: formatDate,
    },
  ];

  return (
    <>
      <form
        className="d-flex flex-column flex-grow-1"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="row">
          <div className=" d-flex flex-row justify-content-between mb-2">
            <h6>Pagamento</h6>

            {!noAction ? (
              <div className="d-flex flex-row align-items-center">
                <p className="fw-bold mb-0 me-2">Ação:</p>
                <div class="btn-group">
                  <button
                    class="btn btn-sm btn-primary dropdown-toggle caret-off"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="true"
                    aria-expanded="false"
                  >
                    <img src={ArrowDown} />
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => setIsTokenModalOpen(true)}
                        disabled={!offerPrice.data?.PRECO}
                      >
                        Token
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="#"
                        onClick={() => {
                          if (entryValue < 0.01) {
                            alert(
                              "Valor de entrada não pode ser menor que R$ 0,01."
                            );
                            return;
                          }
                          if (entryValue > finalPrice) {
                            alert(
                              "Valor de entrada não pode ser maior do que o valor do curso."
                            );
                            return;
                          }
                          generateInstallments();
                        }}
                        disabled={
                          !formData.CODCOLIGADA ||
                          !formData.IDHABILITACAOFILIAL ||
                          !(offerPrice.data && offerPrice.data.PRECO) ||
                          isGeneratingInstallments
                        }
                      >
                        Simular parcelamento
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          {isInstallmentTokenApplied ? (
            <NumberInputComponent
              boldLabel
              label="Parcelamento"
              placeholder="Quantidade de Parcelas"
              className="col-7 col-md-2"
              value={numberOfInstallments}
              onChange={setNumberOfInstallments}
              disabled={isLoadingRecord || noAction}
            />
          ) : (
            <SelectInputComponent
              label="Parcelamento"
              boldLabel
              placeholder={
                paymentMethods.loading || isLoadingRecord
                  ? "Carregando..."
                  : "Parcelamento"
              }
              options={
                paymentMethods.data &&
                paymentMethods.data.map((item) => ({
                  ...item,
                  key: item["FORMAPAG"],
                  label: item["DESCRICAO"] + " - " + item["TIPODOC"],
                }))
              }
              value={formData.FORMAPAG || ""}
              onSelect={(option) => {
                updateFormData("FORMAPAG", option.FORMAPAG);
                updateFormData("TAXA_REAJUSTE", formatNumber(option.ACRESCIMO));
              }}
              disabled={
                !paymentMethods.data ||
                paymentMethods.loading ||
                isLoadingRecord ||
                noAction
              }
              className="col-7 col-md-2"
            />
          )}

          <SelectInputComponent
            label="Modo"
            boldLabel
            placeholder={
              paymentMethods.loading || isLoadingRecord
                ? "Carregando..."
                : "Modo"
            }
            options={[
              {
                key: "P",
                label: "Progressivo",
              },
              {
                key: "F",
                label: "Fixo",
              },
            ]}
            value={formData.MODOPARC || "P"}
            onSelect={(option) => {
              updateFormData("MODOPARC", option.key);
            }}
            disabled={isLoadingRecord || noAction}
            className="col-5 col-md-2"
          />

          <TextInputComponent
            label="Acrescimo"
            boldLabel
            placeholder=""
            disabled={true}
            className="col-12 col-md-2"
            value={
              formData.TAXA_REAJUSTE
                ? formatPercentage(formData.TAXA_REAJUSTE)
                : "Selecione uma forma de pagamento"
            }
          />

          <TextInputComponent
            label="Reajuste Anual"
            boldLabel
            placeholder=""
            disabled={true}
            className="col-12 col-md-2"
            value={
              offerPrice.data && offerPrice.data.TAXA
                ? formatMoney(offerPrice.data.TAXA)
                : ""
            }
          />

          <NumberInputComponent
            label="Entrada"
            boldLabel
            placeholder="Valor de entrada."
            className={`col-12 ${noAction ? "d-none" : "col-md-2"}`}
            value={entryValue}
            onChange={(value) => {
              setEntryValue(value);
            }}
            disabled={isLoadingRecord || noAction}
          />

          <DateInputComponent
            label="Data da Entrada"
            boldLabel
            placeholder={isLoadingRecord ? "Carregando..." : ""}
            className={`col-12 col-md-2`}
            dataKey="DATAINICVENC" // Adiciona o dataKey para identificação no componente
            value={formData.DATAINICVENC || getNextMonthDate()}
            onChange={(key, date) => updateFormData(key, date)} // Passa a chave e o valor para a função de atualização
            disabled={isLoadingRecord || noAction}
          />

          <SelectInputComponent
            label="Data de Vencimento"
            boldLabel
            placeholder="Data de Vencimento"
            options={[
              {
                key: "2",
                label: "Dia 2",
              },
              {
                key: "5",
                label: "Dia 5",
              },
              {
                key: "7",
                label: "Dia 7",
              },
            ]}
            value={installmentsDueDate || ""}
            onSelect={(option) => {
              setInstallmentsDueDate(option.key);
            }}
            disabled={
              !paymentMethods.data ||
              paymentMethods.loading ||
              isLoadingRecord ||
              noAction
            }
            className="col-12 col-md-3"
          />

          <TextInputComponent
            label="Valor do Curso"
            boldLabel
            placeholder={
              offerPrice.loading || isLoadingRecord ? "Carregando..." : ""
            }
            className={`col-12 ${noAction ? "col-md-5" : "col-md-3"}`}
            disabled={true}
            value={formatMoney(offerPrice.data ? offerPrice.data.PRECO : "")}
          />

          <TextInputComponent
            label="Desconto"
            boldLabel
            placeholder=""
            className={`col-12 ${noAction ? "d-none" : "col-md-2"}`}
            disabled={true}
            value={discount ? formatPercentage(discount) : "0"}
          />

          <TextInputComponent
            label="Valor com Desconto"
            boldLabel
            placeholder=""
            className="col-12 col-md-4"
            value={formatMoney(finalPrice)}
            disabled={true}
          />
        </section>

        <div className=" d-flex justify-content-between  align-items-end">
          <div>
            <p>
              <span className="fw-bold">Total:</span>{" "}
              {formatMoney(contractTotalValue)}
            </p>
          </div>
        </div>

        <section>
          {Array.isArray(installments.data) ? (
            <>
              <TableComponent
                data={installments.data}
                fields={installmentsTableFields}
              />
            </>
          ) : (
            ""
          )}

          {!noAction ? (
            <div className=" d-flex justify-content-end  align-items-end">
              <PreviousButtonComponent onClick={previousStep} />
              <NextButtonComponent
                onClick={handleSubmit}
                loading={isSaving || isGeneratingInstallments}
                disabled={!isStepValid}
              />
            </div>
          ) : (
            ""
          )}
        </section>
      </form>

      <TokenModalComponent
        isModalOpen={isTokenModalOpen}
        setIsModalOpen={setIsTokenModalOpen}
        tokensData={tokens}
        onApplyToken={handleTokenApply}
        onRemoveToken={handleTokenRemove}
      />
    </>
  );
};

export default Step5;
