import Base from "./Base";

export default class {
    constructor() {
        this.instance = Base('log')
    }

    logRecordProgress = (codRecord, log) => {
        return this.instance({
            method: 'POST',
            url: '',
            data: {
                'CODFICHA': codRecord,
                'LOG': log
            }
        });
    }

}
