import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/Home";
import Layout from "./Pages/Layout";
import NewRecordPage from "./Pages/Record/New";
import LoginPage from "./Pages/Login";
import RecordSearchPage from "./Pages/Record/Search";
import NewTokenPage from "./Pages/Token/New";
import AdminPage from "./Pages/Admin";
import ViewRecordPage from "./Pages/Record/View";

const Router = () => {
    return (

        <BrowserRouter>
            <Routes>

                <Route path="/entrar" element={<LoginPage />} />

                <Route path="/" element={<Layout />}>
                    <Route index element={<HomePage />} />
                    <Route path="/ficha">
                        <Route index element={<NewRecordPage />} />
                        <Route path="/ficha/ver" element={<ViewRecordPage />} />
                        <Route path="/ficha/pesquisa" element={<RecordSearchPage />} />
                    </Route>
                    <Route path="/token">
                        <Route index element={<NewTokenPage />} />
                    </Route>

                    <Route path="/admin">
                        <Route index element={<AdminPage />} />
                    </Route>
                </Route>

            </Routes>
        </BrowserRouter>
    );
}

export default Router;