import CheckboxInputComponent from "../Inputs/CheckboxInput";
import NextButtonComponent from "../Buttons/NextButton";
import TextInputComponent from "../Inputs/TextInput";
import PreviousButtonComponent from "../Buttons/PreviousButton";
import SelectInputComponent from "../Inputs/SelectInput";
import MetadataService from "../../Services/Metadata";
import ColligateService from "../../Services/Colligates";
import RecordService from "../../Services/Record";
import ReportService from "../../Services/Report";

import { useEffect, useState } from "react";
import {
  cleanCpf,
  getStepsInformation,
  getUniqueObjects,
  notifyError,
  updateStepsInformation,
} from "../../Utils";

const Step2 = ({
  previousStep,
  nextStep,
  codRecord,
  setCodRecord,
  noAction = false,
}) => {
  const metadataService = new MetadataService();
  const colligateService = new ColligateService();
  const recordService = new RecordService();

  const reportService = new ReportService();

  useEffect(() => {
    if (!noAction) {
      reportService.logRecordProgress(codRecord, "Seleção de contexto da matricula.");
    }
  }, []);

  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({});
  const [isLoadingRecord, setIsLoadingRecord] = useState(true);
  const [colligates, setColligates] = useState(null);
  const [isLoadingColligates, setIsLoadingColligates] = useState(true);
  const [branchesAndPeriods, setBranchesAndPeriods] = useState(null);
  const [isLoadingBranchesAndPeriods, setIsLoadingBranchesAndPeriods] =
    useState(false);
  const [offers, setOffers] = useState(null);
  const [isLoadingOffers, setIsLoadingOffers] = useState(false);
  const [isStepValid, setIsStepValid] = useState(false);

  const [isLoadingSecondCourseOffers, setIsLoadingSecondCourseOffers] =
    useState(false);
  const [secondCourseOffers, setSecondCourseOffers] = useState([]);

  useEffect(() => {
    console.log({ secondCourseOffers });
  }, [secondCourseOffers]);

  useEffect(() => {
    if (formData.CODCOLIGADA && formData.CODFILIAL && formData.IDPERLET2) {
      setSecondCourseOffers(null);
      setIsLoadingSecondCourseOffers(true);
      colligateService
        .getOffers(formData.CODCOLIGADA, formData.CODFILIAL, formData.IDPERLET2)
        .then((response) => setSecondCourseOffers(response.data.data))
        .catch(notifyError)
        .finally(() => setIsLoadingSecondCourseOffers(false));
    }
  }, [formData.CODCOLIGADA, formData.CODFILIAL, formData.IDPERLET2]);

  useEffect(() => {
    const isValid =
      formData.CODCOLIGADA &&
      formData.CODFILIAL &&
      formData.IDPERLET &&
      formData.IDHABILITACAOFILIAL &&
      formData.CODTURMA;

    setIsStepValid(isValid);

    console.log({ isValid, formData });
  }, [formData]);

  // Carrega dados iniciais do registro
  useEffect(() => {
    setIsLoadingRecord(true);
    recordService
      .getRecordById(codRecord)
      .then((response) => {
        setFormData(response.data.data);
      })
      .catch(notifyError)
      .finally(() => setIsLoadingRecord(false));
  }, [codRecord]);

  // Carrega coligadas
  useEffect(() => {


    setIsLoadingColligates(true);
    colligateService
      .getColligates()
      .then((response) => setColligates(response.data.data))
      .catch(notifyError)
      .finally(() => setIsLoadingColligates(false));
  }, []);

  // Carrega filiais e períodos de uma coligada selecionada
  useEffect(() => {
    if (formData.CODCOLIGADA) {
      setBranchesAndPeriods(null);
      setIsLoadingBranchesAndPeriods(true);
      colligateService
        .getBranchesAndPeriods(formData.CODCOLIGADA)
        .then((response) => setBranchesAndPeriods(response.data.data))
        .catch(notifyError)
        .finally(() => setIsLoadingBranchesAndPeriods(false));
    }
  }, [formData.CODCOLIGADA]);

  // Carrega ofertas de curso com base nos dados selecionados
  useEffect(() => {
    if (formData.CODCOLIGADA && formData.CODFILIAL && formData.IDPERLET) {
      setOffers(null);
      setIsLoadingOffers(true);
      colligateService
        .getOffers(formData.CODCOLIGADA, formData.CODFILIAL, formData.IDPERLET)
        .then((response) => setOffers(response.data.data))
        .catch(notifyError)
        .finally(() => setIsLoadingOffers(false));
    }
  }, [formData.CODCOLIGADA, formData.CODFILIAL, formData.IDPERLET]);

  // Manipula o envio do formulário
  const handleSubmit = () => {
    setIsSaving(true);
    recordService
      .updateRecord(codRecord, formData)
      .then(() => nextStep())
      .catch(notifyError)
      .finally(() => setIsSaving(false));
  };

  return (
    <form
      className="d-flex flex-column flex-grow-1"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <section className="row">
        <h6 className="fw-bold">Dados da Ficha</h6>

        <SelectInputComponent
          label="Coligada"
          placeholder={
            isLoadingColligates || isLoadingRecord
              ? "Carregando..."
              : "Selecione uma Coligada"
          }
          disabled={!colligates || isLoadingRecord || noAction}
          className="col-12 col-md-6 col-lg-6"
          options={
            colligates &&
            colligates.map((item) => ({
              ...item,
              key: item["CODCOLIGADA"],
              label: item["NOME"],
            }))
          }
          value={formData.CODCOLIGADA || ""}
          onSelect={(selectedOption) => {
            if (!selectedOption) {
              setOffers(null);
              setSecondCourseOffers(null);
              setBranchesAndPeriods(null);
            }

            setFormData((prevData) => ({
              ...prevData,
              CODCOLIGADA: selectedOption.CODCOLIGADA,
              CODTURMA: null,
              CODTURMA2: null,
              IDPERLET: null,
              CODFILIAL: null,
              IDHABILITACAOFILIAL: null,
              IDHABILITACAOFILIAL2: null,
            }));
          }}
        />
      </section>

      <section className="row mt-3">
        <h6 className="fw-bold">Curso</h6>

        <SelectInputComponent
          label="Filial/Período Letivo"
          placeholder={
            isLoadingBranchesAndPeriods || isLoadingRecord
              ? "Carregando"
              : "Selecione uma Filial/Periodo"
          }
          className="col-12 col-md-6 col-lg-6"
          disabled={
            !formData.CODCOLIGADA ||
            !branchesAndPeriods ||
            isLoadingBranchesAndPeriods ||
            isLoadingRecord ||
            noAction
          }
          options={
            branchesAndPeriods &&
            branchesAndPeriods.map((item) => ({
              ...item,
              key: item["IDPERLET"],
              label: item["NOME"],
            }))
          }
          value={formData.IDPERLET || ""}
          onSelect={(selectedOption) => {
            if (!selectedOption) {
              setOffers(null);
              setSecondCourseOffers(null);
            }

            setFormData((prevData) => ({
              ...prevData,
              IDPERLET: selectedOption.IDPERLET,
              CODFILIAL: selectedOption.CODFILIAL,
              IDHABILITACAOFILIAL: null,
              IDPERLET2: null,
              IDHABILITACAOFILIAL2: null,
              CODTURMA: null,
              CODTURMA2: null,
            }));
          }}
        />

        <SelectInputComponent
          label="Curso"
          boldLabel
          placeholder={
            isLoadingOffers || isLoadingRecord
              ? "Carregando..."
              : "Selecione um Curso"
          }
          className="col-12 col-md-6"
          options={
            offers &&
            offers.map((item) => ({
              ...item,
              key: item["CODTURMA"],
              label:
                item["NOMECURSO"] +
                " - " +
                item["NOMETURNO"] +
                " - " +
                item["CODTURMA"],
            }))
          }
          disabled={
            !formData.CODCOLIGADA ||
            !branchesAndPeriods ||
            !formData.CODFILIAL ||
            !offers ||
            isLoadingRecord ||
            noAction
          }
          value={formData.CODTURMA || ""}
          onSelect={(selectedOption) => {
            setFormData((prevData) => ({
              ...prevData,
              CODTURMA: selectedOption.CODTURMA,
              IDHABILITACAOFILIAL: selectedOption.IDHABILITACAOFILIAL,
            }));
          }}
        />
      </section>

      <section className="row mt-3">
        <h6 className="fw-bold">Segundo Curso</h6>

        <SelectInputComponent
          label="Filial/Período Letivo"
          placeholder={
            isLoadingBranchesAndPeriods || isLoadingRecord
              ? "Carregando"
              : "Selecione uma Filial/Periodo"
          }
          className="col-12 col-md-6 col-lg-6"
          disabled={
            !formData.CODCOLIGADA ||
            !formData.CODFILIAL ||
            !branchesAndPeriods ||
            isLoadingBranchesAndPeriods ||
            isLoadingRecord ||
            noAction
          }
          options={
            branchesAndPeriods &&
            branchesAndPeriods
              .filter((item) => item.CODFILIAL == formData.CODFILIAL)
              .map((item) => ({
                ...item,
                key: item["IDPERLET"],
                label: item["NOME"],
              }))
          }
          value={formData.IDPERLET2 || ""}
          onSelect={(selectedOption) =>
            setFormData((prevData) => ({
              ...prevData,
              IDPERLET2: selectedOption.IDPERLET,
              IDHABILITACAOFILIAL2: null,
              CODTURMA2: null,
            }))
          }
        />

        <SelectInputComponent
          label="Curso"
          boldLabel
          placeholder={
            isLoadingOffers || isLoadingSecondCourseOffers || isLoadingRecord
              ? "Carregando..."
              : "Selecione um Curso"
          }
          className="col-12 col-md-6"
          options={
            secondCourseOffers &&
            secondCourseOffers.map((item) => ({
              ...item,
              key: item["CODTURMA"],
              label:
                item["NOMECURSO"] +
                " - " +
                item["NOMETURNO"] +
                " - " +
                item["CODTURMA"],
            }))
          }
          disabled={
            !formData.CODCOLIGADA ||
            !branchesAndPeriods ||
            !secondCourseOffers ||
            isLoadingSecondCourseOffers ||
            !formData.IDPERLET2 ||
            noAction
          }
          value={formData.CODTURMA2 || ""}
          onSelect={(selectedOption) => {
            setFormData((prevData) => ({
              ...prevData,
              CODTURMA2: selectedOption.CODTURMA,
              IDHABILITACAOFILIAL2: selectedOption.IDHABILITACAOFILIAL,
            }));
          }}
        />
      </section>

      {!noAction ? (
        <div className="flex-grow-1 d-flex justify-content-end align-items-end">
          <PreviousButtonComponent onClick={previousStep} />
          <NextButtonComponent
            onClick={handleSubmit}
            loading={isSaving}
            disabled={!isStepValid}
          />
        </div>
      ) : (
        ""
      )}
    </form>
  );
};

export default Step2;
