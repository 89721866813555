import { useEffect, useState } from "react";
import PageTitle from "../../Components/PageTitle";
import RecordSearchFormComponent from "../../Components/RecordSearchForm";
import TableComponent from "../../Components/Table";

import RecordService from "../../Services/Record";
import { notifyError } from "../../Utils";
import { useNavigate, createSearchParams } from "react-router-dom";

import SearchIcon from "../../Assets/Icons/search.svg";

const RecordSearchPage = () => {
  const navigate = useNavigate();
  const fields = [
    {
      key: "CODFICHA",
      title: "ID",
    },
    {
      key: "CPF",
      title: "CPF",
    },
    {
      key: "NOME",
      title: "Nome",
    },
    {
      key: "CODFILIAL",
      title: "Filial",
    },
    {
      key: "CURSO",
      title: "Curso",
    },
    {
      key: "RECMODIFIEDON",
      title: "Ultima Alteração",
      format: (dataISO) => {
        const data = new Date(dataISO);
        const dia = String(data.getDate()).padStart(2, "0");
        const mes = String(data.getMonth() + 1).padStart(2, "0"); // Meses são 0-11
        const ano = data.getFullYear();
        const horas = String(data.getHours()).padStart(2, "0");
        const minutos = String(data.getMinutes()).padStart(2, "0");

        return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
      },
    },
    {
      title: "Ver",
      component: (row) => (
        <>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() =>
              navigate({
                pathname: "/ficha/ver",
                search: createSearchParams({
                  id: row.CODFICHA,
                }).toString(),
              })
            }
          >
            <img src={SearchIcon} />
          </button>
        </>
      ),
    },
  ];

  const recordService = new RecordService();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = (filters) => {
    setIsLoading(true);
    recordService
      .searchRecords(filters)

      .then((response) => {
        setData(response.data.data);
      })

      .catch((error) => {
        notifyError(error);
        alert("Erro ao buscar registros");
      })

      .finally(() => {
        setIsLoading(false);
      });
  };

  const [isFormCollapsed, setIsFormCollapsed] = useState(false);

  return (
    <>
      <PageTitle text="Busca de Fichas" />

      <RecordSearchFormComponent
        onSubmit={handleSubmit}
        isFormCollapsed={isFormCollapsed}
        setIsFormCollapsed={setIsFormCollapsed}
        isLoading={isLoading}
      />

      <section className="mt-3">
        {isLoading || !data ? (
          ""
        ) : (
          <>
            <p className="fs-5 mb-0">Resultados</p>
            <TableComponent data={data} fields={fields} />
          </>
        )}
      </section>
    </>
  );
};

export default RecordSearchPage;
