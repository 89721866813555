import { useState, useEffect } from "react";
import TextInputComponent from "../Inputs/TextInput";
import PreviousButtonComponent from "../Buttons/PreviousButton";
import NextButtonComponent from "../Buttons/NextButton";
import PhoneInputComponent from "../Inputs/PhoneInput";
import CepInputComponent from "../Inputs/CepInput";
import RecordService from "../../Services/Record";
import SelectInputComponent from "../Inputs/SelectInput";
import LocationService from "../../Services/Location";
import {
  calculateAge,
  compararStrings,
  notifyError,
  validateCep,
} from "../../Utils";
import GenericButtonComponent from "../Buttons/GenericButton";
import ReportService from "../../Services/Report";

const StepContactInfo = ({
  previousStep,
  nextStep,
  codRecord,
  noAction = false,
}) => {
  const recordService = new RecordService();
  const locationService = new LocationService();
  const reportService = new ReportService();

  useEffect(() => {
    if (!noAction) {
      reportService.logRecordProgress(
        codRecord,
        "Dados de endereço e contato do responsável financeiro."
      );
    }
  }, []);

  const [isStepValid, setIsStepValid] = useState(false);

  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoadingRecord, setIsLoadingRecord] = useState(true);
  const [residenceCity, setResidenceCity] = useState({
    loading: false,
    data: null,
  });
  const [residenceState, setResidenceState] = useState({
    loading: false,
    data: null,
  });

  const useStudentData = () => {
    if (!noAction) {
      reportService.logRecordProgress(
        codRecord,
        "Dados de endereço e contato do Aluno copiados para o responsável financeiro."
      );
    }
    updateFormData("RF_CEP", formData.CEP);
    updateFormData("RF_RUA", formData.RUA);
    updateFormData("RF_NUMERO", formData.NUMERO);
    updateFormData("RF_COMPLEMENTO", formData.COMPLEMENTO);
    updateFormData("RF_BAIRRO", formData.BAIRRO);
    updateFormData("RF_CIDADE", formData.CIDADE);
    updateFormData("RF_CODETD", formData.ESTADO);
    updateFormData("RF_FONECEL", formData.TELEFONE1);
    updateFormData("RF_FONERES", formData.TELEFONE2);
    updateFormData("RF_FONECOM", formData.TELEFONE3);
    updateFormData("RF_EMAIL", formData.EMAIL);

    residenceCity.data.forEach((item) => {
      if (compararStrings(item["NOME"], formData.CIDADE)) {
        updateFormData("RF_CODMUNICIPIO", item["CODMUNICIPIO"]);
      }
    });
  };

  useEffect(() => {
    setIsStepValid(
      formData.RF_RUA &&
        formData.RF_NUMERO &&
        formData.RF_BAIRRO &&
        formData.RF_CODETD &&
        formData.RF_CIDADE &&
        formData.RF_FONECEL &&
        formData.RF_EMAIL
    );
  }, [formData]);

  const updateFormData = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  useEffect(() => {
    setIsLoadingRecord(true);
    recordService
      .getRecordById(codRecord)
      .then((response) => setFormData(response.data.data))
      .finally(() => setIsLoadingRecord(false));
  }, [codRecord]);

  const handleSubmit = () => {
    setIsSaving(true);
    recordService
      .updateRecord(codRecord, formData)
      .then(() => nextStep())
      .catch(notifyError)
      .finally(() => setIsSaving(false));
  };

  useEffect(() => {
    setResidenceState((prevState) => ({
      ...prevState,
      data: null,
      loading: true,
    }));
    locationService
      .getStates(1)
      .then((response) =>
        setResidenceState({ loading: false, data: response.data.data })
      )
      .catch((error) => {
        notifyError(error);
        setResidenceState({ loading: false, data: null });
      });
  }, []);

  useEffect(() => {
    if (formData.RF_CODETD) {
      setResidenceCity((prevState) => ({ ...prevState, loading: true }));
      locationService
        .getCities("1", formData.RF_CODETD)
        .then((response) =>
          setResidenceCity({ loading: false, data: response.data.data })
        )
        .catch((error) => {
          setResidenceCity({ loading: false, data: null });
          notifyError(error);
        });
    }
  }, [formData.RF_CODETD]);

  useEffect(() => {
    console.log({ formData });
  }, [formData]);

  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [addressError, setAddressError] = useState("");

  const searchAddress = () => {
    if (validateCep(formData.RF_CEP) != true) {
      return;
    }
    setIsLoadingAddress(true);
    locationService
      .getAddress(formData.RF_CEP)
      .then((response) => {
        updateFormData("RF_RUA", response.data.data.logradouro);
        updateFormData("RF_NUMERO", "");
        updateFormData("RF_COMPLEMENTO", "");
        updateFormData("RF_BAIRRO", response.data.data.bairro);
        updateFormData("RF_CODETD", response.data.data.uf);

        residenceCity.data.forEach((item) => {
          if (item.CODMUNICIPIOIBGE == response.data.data.ibge) {
            console.log({ cityFound: item });
            updateFormData("RF_CODMUNICIPIO", item.CODMUNICIPIO);
            updateFormData("RF_CIDADE", item.NOME);
          }
        });
      })
      .catch((error) => {
        alert(error.response.data.message);
        updateFormData("RF_RUA", "");
        updateFormData("RF_NUMERO", "");
        updateFormData("RF_COMPLEMENTO", "");
        updateFormData("RF_BAIRRO", "");
        updateFormData("RF_CODETD", "");

        updateFormData("RF_CIDADE", "");
      })
      .finally(() => {
        setIsLoadingAddress(false);
      });
  };

  return (
    <form
      className="d-flex flex-column flex-grow-1"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <section className="row">
        <h6>Endereço de Residência do Responsável Financeiro</h6>

        <CepInputComponent
          label="CEP"
          boldLabel
          placeholder={isLoadingRecord ? "Carregando..." : ""}
          className="col-12 col-md-4"
          dataKey="RF_CEP"
          value={formData.RF_CEP || ""}
          onChange={updateFormData}
          disabled={isLoadingRecord || noAction}
          searchable={!noAction}
          onSearch={searchAddress}
          loading={isLoadingAddress}
        />

        <TextInputComponent
          label="Rua"
          boldLabel
          placeholder={isLoadingRecord ? "Carregando..." : ""}
          className="col-12 col-md-8"
          dataKey="RF_RUA"
          value={formData.RF_RUA || ""}
          onChange={updateFormData}
          disabled={isLoadingRecord || noAction}
        />

        <TextInputComponent
          label="Número"
          boldLabel
          placeholder={isLoadingRecord ? "Carregando..." : ""}
          className="col-12 col-md-2"
          dataKey="RF_NUMERO"
          value={formData.RF_NUMERO || ""}
          onChange={updateFormData}
          disabled={isLoadingRecord || noAction}
        />

        <TextInputComponent
          label="Complemento"
          boldLabel
          placeholder={isLoadingRecord ? "Carregando..." : ""}
          className="col-12 col-md-4"
          dataKey="RF_COMPLEMENTO"
          value={formData.RF_COMPLEMENTO || ""}
          onChange={updateFormData}
          disabled={isLoadingRecord || noAction}
        />

        <TextInputComponent
          label="Bairro"
          boldLabel
          placeholder={isLoadingRecord ? "Carregando..." : ""}
          className="col-12 col-md-6"
          dataKey="RF_BAIRRO"
          value={formData.RF_BAIRRO || ""}
          onChange={updateFormData}
          disabled={isLoadingRecord || noAction}
        />

        <SelectInputComponent
          label="Cidade"
          boldLabel
          placeholder={
            residenceCity.loading || residenceState.loading || isLoadingRecord
              ? "Carregando..."
              : "Cidade"
          }
          options={
            residenceCity.data &&
            residenceCity.data.map((item) => ({
              ...item,
              key: item["CODMUNICIPIO"],
              label: item["NOME"],
            }))
          }
          value={formData.RF_CODMUNICIPIO || ""}
          onSelect={(option) => {
            updateFormData("RF_CIDADE", option.NOME);
            updateFormData("RF_CODMUNICIPIO", option.CODMUNICIPIO);
          }}
          disabled={
            !residenceCity.data ||
            residenceState.loading ||
            isLoadingRecord ||
            noAction
          }
          className="col-12 col-md-4"
        />

        <SelectInputComponent
          label="Estado"
          boldLabel
          placeholder={
            residenceState.loading || isLoadingRecord
              ? "Carregando..."
              : "Estado"
          }
          options={
            residenceState.data &&
            residenceState.data.map((item) => ({
              ...item,
              key: item["CODESTADO"],
              label: item["CODESTADO"],
            }))
          }
          value={formData.RF_CODETD || ""}
          onSelect={(option) => {
            if (formData.RF_CODETD != option.CODESTADO) {
              updateFormData("RF_CODETD", option.CODESTADO);
              updateFormData("RF_CIDADE", null);
              updateFormData("RF_CODMUNICIPIO", null);
            }
          }}
          disabled={
            !residenceState.data ||
            residenceState.loading ||
            isLoadingRecord ||
            noAction
          }
          className="col-12 col-md-2"
        />
      </section>

      <section className="row mt-3">
        <h6>Contatos</h6>

        <PhoneInputComponent
          label="Celular"
          boldLabel
          placeholder={isLoadingRecord ? "Carregando..." : ""}
          className="col-12 col-md-4"
          dataKey="RF_FONECEL"
          value={formData.RF_FONECEL || ""}
          onChange={updateFormData}
          disabled={isLoadingRecord || noAction}
        />

        <PhoneInputComponent
          label="Telefone Residencial"
          boldLabel
          placeholder={isLoadingRecord ? "Carregando..." : ""}
          className="col-12 col-md-4"
          dataKey="RF_FONERES"
          value={formData.RF_FONERES || ""}
          onChange={updateFormData}
          disabled={isLoadingRecord || noAction}
        />

        <PhoneInputComponent
          label="Telefone Comercial"
          boldLabel
          placeholder={isLoadingRecord ? "Carregando..." : ""}
          className="col-12 col-md-4"
          dataKey="RF_FONECOM"
          value={formData.RF_FONECOM || ""}
          onChange={updateFormData}
          disabled={isLoadingRecord || noAction}
        />

        <TextInputComponent
          label="Email"
          boldLabel
          placeholder={isLoadingRecord ? "Carregando..." : ""}
          className="col-12 col-md-6"
          dataKey="RF_EMAIL"
          value={formData.RF_EMAIL || ""}
          onChange={updateFormData}
          disabled={isLoadingRecord || noAction}
        />
      </section>

      {!noAction ? (
        <div className="flex-grow-1 d-flex justify-content-end align-items-end">
          <PreviousButtonComponent onClick={previousStep} />
          <GenericButtonComponent
            onClick={useStudentData}
            disabled={
              !formData.DTNASCIMENTO || calculateAge(formData.DTNASCIMENTO) < 18
            }
            loading={false}
            title="Usar dados do aluno"
            className="btn-success"
          />
          <NextButtonComponent
            onClick={handleSubmit}
            loading={isSaving}
            disabled={!isStepValid}
          />
        </div>
      ) : (
        ""
      )}
    </form>
  );
};

export default StepContactInfo;
