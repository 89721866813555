import { useState, useEffect } from "react";
import NewRecordSteps from "../../Models/NewRecordSteps";

import { useLocation } from "react-router-dom";
import PageTitle from "../../Components/PageTitle";
import { scrollToTop } from "../../Utils";

const ViewRecordPage = () => {
  const [codRecord, setCodRecord] = useState(null);
  const query = new URLSearchParams(useLocation().search);

  const [elements, setElements] = useState("");
  useEffect(() => {
    if (query.get("id")) {
      setCodRecord(query.get("id"));
      setElements(NewRecordSteps.filter((i) => i.title != "1" && i.title != "8").map(
        (i) => i.element
      ));

      scrollToTop();
    }
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [elements])



  return (
    <>
      <section className="d-flex justify-content-between align-items-center flex-column flex-md-row mb-3 mb-md-0">
        <PageTitle text="Visualização de Ficha" />
      </section>

      {
      codRecord? elements.map((Element) => 
        <>
        <Element codRecord={codRecord} setCodRecord={setCodRecord} noAction={true}/>
        
        </>
      ) : ""
      
      }
    </>
  );
};

export default ViewRecordPage;
