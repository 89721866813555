import CheckboxInputComponent from "../Inputs/CheckboxInput";
import NextButtonComponent from "../Buttons/NextButton";
import TextInputComponent from "../Inputs/TextInput";
import { useEffect, useState } from "react";
import Utils, { cleanCpf, clearStepsInformation, notifyError, updateStepsInformation, validateCpf, validateEmail } from "../../Utils";
import CpfInputComponent from "../Inputs/CpfInput";
import EmailInputComponent from "../Inputs/EmailInput";
import RecordService from "../../Services/Record";
import ReportService from "../../Services/Report";

const Step1 = ({ previousStep, nextStep, codRecord, setCodRecord }) => {

    const [isInitializingRecord, setIsInitializingRecord] = useState(false);

    const reportService = new ReportService();

    useEffect(() => {
        setCodRecord(null);
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        const recordService = new RecordService();

        setIsInitializingRecord(true);

        recordService.initializeRecord(cleanCpf(formData['CPF']), formData['EMAIL'])
            .then((response) => {

                if (response.data) {
                    setCodRecord(response.data.CODFICHA);
                    // reportService.logRecordProgress(response.data.CODFICHA, "Ficha iniciada.");
                    nextStep();
                } else {
                    alert("ERRO")
                }
               
                
            })
            .catch((error) => {
                alert("Houve um erro ao inicializar a ficha.")
                notifyError(error);
            })
            .finally(() => {
                setIsInitializingRecord(false);
            })
    }

    const [formData, setFormData] = useState({});

    const updateFormData = (key, value) => {
        setFormData(
            (prevState) => (
                {
                    ...prevState,
                    [key]: value
                }
            )
        )
    }

    const [isForeigner, setIsForeigner] = useState(false);

    useEffect(() => {
        updateFormData("EMAIL", null)
        updateFormData("CPF", null)

        updateFormData("ESTRANGEIRO", isForeigner)
    }, [isForeigner]);

    const [isStepValid, setIsStepValid] = useState(false);

    useEffect(() => {

        setIsStepValid(
            isForeigner ? validateEmail(formData['EMAIL']) : validateCpf(formData['CPF'])
        )
    }, [formData])

    return (
        <>
            <form className="d-flex flex-column flex-grow-1" onSubmit={handleSubmit}>
                <section>
                    {
                        isForeigner ?
                            <EmailInputComponent
                                label="Email"
                                placeholder="Email do aluno"
                                className="col-12 col-md-5 col-lg-3"
                                dataKey="EMAIL"
                                onChange={updateFormData}
                                value={formData} />

                            : <CpfInputComponent
                                label="CPF"
                                placeholder="CPF do aluno"
                                className="col-12 col-md-5 col-lg-3"
                                dataKey="CPF"
                                onChange={updateFormData}
                                value={formData}
                            />
                    }
                    <CheckboxInputComponent label="Estrangeiro" onChange={setIsForeigner} />
                </section>

                <div className="flex-grow-1 d-flex justify-content-end align-items-end">
                    <NextButtonComponent onClick={handleSubmit} loading={isInitializingRecord} disabled={!isStepValid} />

                </div>
            </form>

        </>
    );
}

export default Step1;