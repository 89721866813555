import LogService from "../Services/Log";
import { getLoginDataFromStorage, updateStepsInformation } from "./storage";
import { useNavigate } from "react-router-dom";

export * from "./formatation";
export * from "./storage";
export * from "./validation";

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export function getNextMonthDate() {
  // Cria um objeto Date com a data atual
  let currentDate = new Date();

  // Adiciona 1 mês à data atual
  currentDate.setMonth(currentDate.getMonth() + 1);

  // Formata a data no formato yyyy-mm-dd
  let year = currentDate.getFullYear();
  let month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adiciona zero à esquerda, se necessário
  let day = String(currentDate.getDate()).padStart(2, "0"); // Adiciona zero à esquerda, se necessário

  // Retorna a data formatada
  return `${year}-${month}-${day}`;
}

export const getUniqueObjects = (arr) => {
  return arr.filter(
    (obj, index, self) =>
      index === self.findIndex((o) => JSON.stringify(o) === JSON.stringify(obj))
  );
};

export const notifyError = (error) => {
  const logService = new LogService();

  logService.saveError({
    error,
    user: getLoginDataFromStorage(),
  });
};

export const calculateAge = (birthDateString) => {
  const today = new Date();
  const birthDate = new Date(birthDateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  // Adjust age if the current month/day is before the birth month/day
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

export const compararStrings = (str1, str2) => {
  // Validação dos parâmetros
  if (typeof str1 !== "string" || typeof str2 !== "string") {
    throw new TypeError("Ambos os parâmetros devem ser strings.");
  }

  // Função auxiliar para remover acentos e converter para minúsculas
  const formatarString = (str) =>
    str
      .normalize("NFD") // Decompõe os caracteres acentuados
      .replace(/[\u0300-\u036f]/g, "") // Remove os diacríticos (acentos)
      .toLowerCase(); // Converte para caixa baixa

  return formatarString(str1) === formatarString(str2);
};
