const NumberInputComponent = ({
    label,
    placeholder,
    className,
    inputClassName = '',
    onChange,
    value,
    boldLabel = false,
    disabled = false

}) => {
    return (
        <div class={`mb-3 ${className}`}>
        {label && <label className={`form-label ${boldLabel ? 'fw-bold' : ''}`}>{label}</label>}
        <input
                type="number"
                min='0'
                className={`form-control rounded-1 ${inputClassName}`}
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
                value={value
                }

                disabled={disabled}
            />
            <div class="form-text"></div>
        </div>
    );
}

export default NumberInputComponent;