import { useEffect, useState } from "react";
import TableComponent from "../Components/Table";
import RecordService from "../Services/Record";
import {
  formatCpf,
  formatMoney,
  getLoginDataFromStorage,
  getProfiles,
  notifyError,
  updateStepsInformation,
} from "../Utils";
import { useNavigate, createSearchParams } from "react-router-dom";

import getRecordStatusesAvailable from "../Models/RecordStatusAvailable";

import EditIcon from "../Assets/Icons/edit.svg";

import ArrowDown from "../Assets/Icons/arrowDown.svg";
import RecordStatusModalComponent from "../Components/Modals/RecordStatusModal";
import MetadataService from "../Services/Metadata";

const HomePage = () => {
  const navigate = useNavigate();





  const fields = [
    {
      key: "CPF",
      title: "CPF",
      format: formatCpf,
    },
    {
      key: "NOME",
      title: "Nome",
    },
    {
      key: "CODFILIAL",
      title: "Filial",
    },
    {
      key: "NOMECURSOCURTO",
      title: "Curso",
      format: (nome) => {
        return nome.split(" - ")[0];
      },
    },
    {
      key: "RECMODIFIEDON",
      title: "Ultima Alteração",
      format: (dataISO) => {
        const data = new Date(dataISO);
        const dia = String(data.getDate()).padStart(2, "0");
        const mes = String(data.getMonth() + 1).padStart(2, "0"); // Meses são 0-11
        const ano = data.getFullYear();
        const horas = String(data.getHours()).padStart(2, "0");
        const minutos = String(data.getMinutes()).padStart(2, "0");

        return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
      },
    },
    {
      title: "Ação",
      component: (row) => (
        <div class="btn-group">
          <button
            class="btn btn-sm btn-primary dropdown-toggle caret-off"
            data-bs-toggle="dropdown"
            data-bs-auto-close="true"
            aria-expanded="false"
          >
            <img src={ArrowDown} />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item"
                href="#"
                onClick={() =>
                  navigate({
                    pathname: "/ficha",
                    search: createSearchParams({
                      id: row.CODFICHA,
                    }).toString(),
                  })
                }
              >
                Editar
              </a>
            </li>
            <li>
              <a
                class="dropdown-item"
                href="#"
                onClick={() =>
                  navigate({
                    pathname: "/ficha/ver",
                    search: createSearchParams({
                      id: row.CODFICHA,
                    }).toString(),
                  })
                }
              >
                Ver
              </a>
            </li>

            {row.STATUSIMP == 98 ? (
              <>
                <li>
                  <a class="dropdown-item" href={`https://api.portaldevendas.lbhm.com.br/records/${row.CODFICHA}/contract`} target="_blank" >
                    Gerar Contrato
                  </a>
                </li>
              </>
            ) : (
              ""
            )}

            {(row.STATUSIMP == 98 && getProfiles().includes("APPVENDA_V")) ||
            getProfiles().includes("APPVENDA_S") ||
            getProfiles().includes("APPVENDA_C") ||
            getProfiles().includes("APPVENDA_ADMIN") ? (
              <>
                <li>
                  <a
                    class="dropdown-item"
                    href="#"
                    onClick={() => {
                      setIsRecordStatusChangeModalOpen(true);
                      setEditingRecord(row);
                    }}
                  >
                    Alterar Status
                  </a>
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
        </div>
      ),
    },
  ];

  const [pendingRecords, setPendingRecords] = useState([]);
  const [isLoadingPendingRecords, setIsLoadingPendingRecords] = useState(true);

  const [statusesAvailable, setStatusesAvailable] = useState([]);
  const [editingRecord, setEditingRecord] = useState(null);

  const recordService = new RecordService();
  const metadataService = new MetadataService();

  const [isRecordStatusChangeModalOpen, setIsRecordStatusChangeModalOpen] =
    useState(false);

  const [isRecordContractModalOpen, setIsRecordContractModalOpen] =
    useState(false);

  useEffect(() => {
    setIsLoadingPendingRecords(true);
    recordService
      .getMyPendingRecords()

      .catch(notifyError)

      .then((response) => {
        setPendingRecords(response.data.data);
      })
      .finally(() => setIsLoadingPendingRecords(false));
  }, []);

  const [isLoadingUserStatistics, setIsLoadingUserStatistics] = useState(true);
  const [userStatistics, setUserStatistics] = useState({});

  useEffect(() => {
    setIsLoadingUserStatistics(true);
    metadataService
      .getUserStatistics()
      .then((response) => {
        if (response.data.data.length > 0) {
          setUserStatistics(response.data.data[0]);
        }
      })
      .finally(() => {
        setIsLoadingUserStatistics(false);
      });
  }, []);

  const generateContract = (codficha) => {
      recordService.generateContract(codficha)
      .then((response) => {
        console.log({contractResponse:response});
        window.location.reload();
      })
  }

  return (
    <>
      <p className="h3">Olá, {getLoginDataFromStorage().user.NOME}!</p>

      {!isLoadingUserStatistics && userStatistics ? (
        <>
          {" "}
          <section className="row d-none d-md-flex">
            <div
              className="text-white px-2 py-1 m-1 rounded-1"
              style={{ width: "140px", backgroundColor: "#183b71" }}
            >
              <p className="mb-0 fw-bold fs-3">{userStatistics.VENDAS}</p>
              <p className="mb-0">Total</p>
            </div>

            <div
              className="text-white px-2 py-1 m-1 rounded-1"
              style={{ width: "170px", backgroundColor: "#009462" }}
            >
              <p className="mb-0 fw-bold fs-3">
                {userStatistics.VENDASCONFIRMADAS}
              </p>
              <p className="mb-0">Confirmadas</p>
            </div>

            <div
              className="text-white px-2 py-1 m-1 rounded-1"
              style={{ width: "160px", backgroundColor: "#f58634" }}
            >
              <p className="mb-0 fw-bold fs-3">
                {userStatistics.VENDAPENDENTES}
              </p>
              <p className="mb-0">Pendentes</p>
            </div>

            <div
              className="text-white px-2 py-1 m-1 rounded-1"
              style={{ width: "170px", backgroundColor: "#008dcf" }}
            >
              <p className="mb-0 fw-bold fs-3">
                {formatMoney(userStatistics.VALORCONTRATOPENDENTE || "") || ""}
              </p>
              <p className="mb-0 fs-6">Valor Contrato Pendente</p>
            </div>
          </section>
          <section className="row d-flex d-md-none g-2 mt-2">
            <div className="col-6 ">
              <div
                className="text-white px-2 py-1 rounded-1-2 "
                style={{ backgroundColor: "#183b71" }}
              >
                <p className="mb-0 fw-bold fs-3">{userStatistics.VENDAS}</p>
                <p className="mb-0">Total</p>
              </div>
            </div>

            <div className="col-6 ">
              <div
                className="text-white px-2 py-1 rounded-1-2  "
                style={{ backgroundColor: "#009462" }}
              >
                <p className="mb-0 fw-bold fs-3">
                  {userStatistics.VENDASCONFIRMADAS}
                </p>
                <p className="mb-0">Confirmadas</p>
              </div>
            </div>

            <div className="col-6">
              <div
                className="text-white px-2 py-1 rounded-1-2 "
                style={{ backgroundColor: "#f58634" }}
              >
                <p className="mb-0 fw-bold fs-3">
                  {userStatistics.VENDAPENDENTES}
                </p>
                <p className="mb-0">Pendentes</p>
              </div>
            </div>

            <div className="col-6 ">
              <div
                className="text-white px-2 py-1 rounded-1-2 "
                style={{ backgroundColor: "#008dcf" }}
              >
                <p className="mb-0 fw-bold fs-3">
                  {formatMoney(userStatistics.VALORCONTRATOPENDENTE || "") || ""}
                </p>
                <p className="mb-0 fs-6">Valor Contrato Pendente</p>
              </div>
            </div>
          </section>
        </>
      ) : (
        ""
      )}

      <section className="mt-3">
        {isLoadingPendingRecords ? (
          <p className="fs-5">
            <span
              class="spinner-border spinner-border-sm me-1"
              aria-hidden="true"
            ></span>
            Carregando
          </p>
        ) : pendingRecords.length > 0 ? (
          <>
            <p className="fs-5 mb-0">Suas Fichas Pendentes</p>

            <TableComponent data={pendingRecords} fields={fields} />
          </>
        ) : (
          <p>Sem registros encontrados</p>
        )}
      </section>

      <RecordStatusModalComponent
        isModalOpen={isRecordStatusChangeModalOpen}
        setIsModalOpen={setIsRecordStatusChangeModalOpen}
        recordData={editingRecord}
      />
    </>
  );
};

export default HomePage;
