import RecordFormStep1 from "../Components/RecordForm/Step1";
import RecordFormStep2 from "../Components/RecordForm/Step2";
import RecordFormStep3 from "../Components/RecordForm/Step3";
import RecordFormStep4 from "../Components/RecordForm/Step4";
import RecordFormStep5 from "../Components/RecordForm/Step5";
import RecordFormStep6 from "../Components/RecordForm/Step6";
import RecordFormStep7 from "../Components/RecordForm/Step7";
import RecordFormStep8 from "../Components/RecordForm/Step8";

export default [
    {
        "title":"1",
        "element": RecordFormStep1
    },
    {
        "title":"2",
        "element": RecordFormStep2
    },
    {
        "title":"3",
        "element": RecordFormStep3
    },

    {
        "title":"4",
        "element": RecordFormStep4
    },
    {
        "title":"5",
        "element": RecordFormStep5
    },
    {
        "title":"6",
        "element": RecordFormStep6
    },
    {
        "title":"7",
        "element": RecordFormStep7
    },
    {
        "title":"8",
        "element": RecordFormStep8
    }
]