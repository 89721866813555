
import { getProfiles } from "../Utils";

const getRecordStatusesAvailable = (currentStatus=null) => { 

    return  [
        {
            "codStatus": "02",
            "description": "Em preenchimento",
            "color": "#F58634",
            "enabled": (getProfiles().includes("APPVENDA_V") && currentStatus == "98") || getProfiles().includes("APPVENDA_C") || getProfiles().includes("APPVENDA_S") || getProfiles().includes("APPVENDA_ADMIN")
        },
        {
            "codStatus": "98",
            "description": "Finalizada - aguardando contrato",
            "color": "#008DD0",
            "enabled": getProfiles().includes("APPVENDA_C") || getProfiles().includes("APPVENDA_S") || getProfiles().includes("APPVENDA_ADMIN")
        },
        {
            "codStatus": "97",
            "description": "Finalizada - aguardando importação",
            "color": "#009462",
            "enabled": (getProfiles().includes("APPVENDA_V") && currentStatus == "98") || getProfiles().includes("APPVENDA_C") || getProfiles().includes("APPVENDA_S") || getProfiles().includes("APPVENDA_ADMIN")
        },
        {
            "codStatus": "96",
            "description": "Descartada",
            "color": "#C74148",
            "enabled": getProfiles().includes("APPVENDA_C") || getProfiles().includes("APPVENDA_S") || getProfiles().includes("APPVENDA_ADMIN")
        }
    ];    
}

export default getRecordStatusesAvailable;