import CheckboxInputComponent from "../Inputs/CheckboxInput";
import NextButtonComponent from "../Buttons/NextButton";
import TextInputComponent from "../Inputs/TextInput";
import PreviousButtonComponent from "../Buttons/PreviousButton";
import SelectInputComponent from "../Inputs/SelectInput";
import MetadataService from "../../Services/Metadata";
import ColligateService from "../../Services/Colligates";
import ReportService from "../../Services/Report";

import { useEffect, useState } from "react";
import {
  cleanCpf,
  getStepsInformation,
  getUniqueObjects,
  notifyError,
  scrollToTop,
  updateStepsInformation,
} from "../../Utils";
import NewRecordSteps from "../../Models/NewRecordSteps";
import GenericButtonComponent from "../Buttons/GenericButton";
import RecordService from "../../Services/Record";

const Step8 = ({ previousStep, nextStep, codRecord, setCodRecord }) => {

  const reportService = new ReportService();
  const recordService = new RecordService();

  const [isSaving, setIsSaving] = useState(false);
  useEffect(() => {
    reportService.logRecordProgress(codRecord, "Ficha em revisão.");
  }, [])
  

  const [elements, setElements] = useState("");
  useEffect(() => {
    setElements(
      NewRecordSteps.filter((i) => i.title != "1" && i.title != "8").map(
        (i) => i.element
      )
    );
    scrollToTop();
  }, []);

  return (
    <>
      {codRecord && elements.length > 0
        ? elements.map((Element) => (
            <>
              <Element
                codRecord={codRecord}
                setCodRecord={setCodRecord}
                noAction={true}
              />
            </>
          ))
        : ""}

      <div className=" d-flex justify-content-end  align-items-end">
        <PreviousButtonComponent onClick={previousStep} />
        <GenericButtonComponent
        loading={isSaving}
        disabled={isSaving}
          onClick={() => {

            setIsSaving(true);
            recordService
              .updateRecord(codRecord, {
                "STATUSIMP": 98
              })
              .then(() => nextStep())
              .catch(notifyError)
              .finally(() => setIsSaving(false));
          }}
          title="Confirmar e salvar"
          className="btn-success"
        />

      </div>
    </>
  );
};

export default Step8;
