import AddIcon from "../Assets/Icons/Sidebar/add.svg";
import SearchIcon from "../Assets/Icons/Sidebar/search.svg";
import KeyIcon from "../Assets/Icons/Sidebar/key.svg";
import AdminIcon from "../Assets/Icons/Sidebar/admin.svg";

import { getProfiles } from "../Utils";



const getMainMenuMetadata = () => {
    return [
        {
            "title": "Comercial",
            "enabled": true,
            "options": [
                {
                    "title": "Cadastro de Ficha",
                    "icon": AddIcon,
                    "url": "/ficha",
                    "enabled":  getProfiles().includes("APPVENDA_V")
                },
                {
                    "title": "Busca de Ficha",
                    "icon": SearchIcon,
                    "url": "/ficha/pesquisa",
                    "enabled": getProfiles().includes("APPVENDA_V")
    
                },
                {
                    "title": "Geração de Token",
                    "icon": KeyIcon,
                    "url": "/token",
                    "enabled":  getProfiles().includes("APPVENDA_S")
                }
            ]
        },
        {
            "title": "Sistema",
            "enabled": getProfiles().includes("APPVENDA_ADMIN"),
            "options": [
                {
                    "title": "Administração",
                    "icon": AdminIcon,
                    "url": "/admin",
                    "enabled":  getProfiles().includes("APPVENDA_ADMIN")
                }
            ]
        }
    ];
}


export default getMainMenuMetadata; 